<template>
    <div>
        <input hidden type="text" class="form-control" id="DeliveryOrderId" v-model="DeliveryOrderId">
        <div class="modal fade" id="DeliveryOrderStatusModal" tabindex="-1" aria-labelledby="DeliveryOrderStatusModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="DeliveryOrderStatusModalLabel" class="font-weight-bold">{{ModalTitle}}{{DeliveryOrderNo}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                        </div>
                       
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import {globalfunc} from '../../../../shared/GlobalFunction';
import DeliveryOrderServices from '../Script/DeliveryOrderServices';
import { response } from '../../../../infrastructure/constant/response';
import { sweet_alert } from '../../../../infrastructure/constant/variable';

export default {
    name: 'DeliveryOrderStatusForm',
    components: {
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle : '',

            DeliveryOrderId : '',
            DeliveryOrderNo : '',
            Status : '',
            StatusData : [],
        }
    },
    methods: {
        async statusClick(doData){
            this.ModalTitle = 'Edit Status Delivery Order: ';
            this.DeliveryOrderId = doData.delivery_order_id;
            this.DeliveryOrderNo = doData.delivery_order_number;
            
            this.StatusData = await globalfunc.globalDropdown('ddl_status_delivery_order');
            this.Status = doData.status;
            
            window.$('#DeliveryOrderStatusModal').modal('show');
        },
        async saveClick(){
            var data = await DeliveryOrderServices.getDeliveryOrderDetail(this.DeliveryOrderId);
            var detailDO_Array = globalfunc.objectToArrayConverter(data.delivery_order_detail, "TransactionDO-DetailDOArrayInput");

            const doData = {
                delivery_order_type_id: data.delivery_order_type_id,
                delivery_order_number: data.delivery_order_number,
                status: this.Status,
                delivery_order_date: data.delivery_order_date,
                purchase_order_id: parseInt(data.purchase_order_id),
                notes: data.notes,
                attachment: null,    
                driver_id: data.driver_id,
                vehicle_id: data.vehicle_id,
                expedition_id: data.expedition_id,
                additional_notes: data.additional_notes,                     
                transaction_delivery_order_detail: detailDO_Array
            }

            const variables = {
                id: parseInt(this.DeliveryOrderId),
                data : doData
            }
            
            this.$swal(sweet_alert.update_status).then((result) => {
                if (result.isConfirmed == true) {
                    DeliveryOrderServices.editQuery(variables).then(res => {
                        window.$('#DeliveryOrderStatusModal').modal('hide');
                        this.$swal("Info", response.successUpdate, "success");
                        this.$props.reload();
                    }).catch(error => {
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
    }
}
</script>

<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>